import { billingLoad } from './billingLoad.public'
import { usePaymentStore } from './payment.store'

const SESSION_STORE_KEY = 'payment-show-in-start-app'

export function useBillingStartApp() {
  const store = usePaymentStore()
  const modal = useModal()

  return async () => {
    await billingLoad()
    if (sessionStorage.getItem(SESSION_STORE_KEY) === 'true') return
    if (!store.hasPurchase) {
      sessionStorage.setItem(SESSION_STORE_KEY, 'true')
      modal.emit('payment_open', { entry: 'start-session' })
    }
  }
}

export function offBillingStartApp() {
  sessionStorage.setItem(SESSION_STORE_KEY, 'true')
}
