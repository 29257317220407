import { usePaymentStore } from './payment.store'

import { paymentBillingGet } from '../api/paymentBillingGet.api'

export async function billingLoad() {
  const store = usePaymentStore()
  const billing = await paymentBillingGet()
  if (!billing) return false
  store.hasPurchase = billing.hasPurchase
  store.subscriptionActive = billing.subscriptionActive
  console.log('billing', billing)
  return true
}
