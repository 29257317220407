import type { PaymentBilling } from '../types'

type ApiResponse = {
  success: boolean
  billing: PaymentBilling
}

const API_REQUEST_PATH = '/payment/billing'

export async function paymentBillingGet() {
  const response = await useApi<ApiResponse>(API_REQUEST_PATH)
  if (!response.isOk || !response.data?.success) return
  return response.data.billing
}
